import { useEffect, useState } from "react";

import { filesize } from "filesize";
// eslint-disable-next-line no-restricted-imports
import moment from "moment";
import { unparse } from "papaparse";

import {
  getRequestTotalValues,
  transformServiceApproval,
} from "../../../../backend/common/requestTotals";
import { RequestDataType } from "../providers/ChatProvider";

export const MOBILE_MEDIA_QUERY = "(max-width: 1253px)";

export const MAXINT32 = 2147483647;

export const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  minWidth: 400,
  maxWidth: 550,
  borderRadius: 1,
  zIndex: 9999,
};

export const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const dateTimeFormatLocaleOptions: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
};

export const FileExtensionsFromContentType = new Map([
  ["image/jpeg", "jpg"],
  ["image/png", "png"],
  ["image/gif", "gif"],
  ["image/bmp", "bmp"],
  ["image/webp", "webp"],
  ["image/svg+xml", "svg"],
  ["image/tiff", "tiff"],
  ["image/jp2", "jp2"],
]);

export const normalizePhoneNumber = (
  phone: string,
  addCountryCode = true
): string => {
  // convert phone # to only numbers, optionally prefixed by +1
  phone = phone?.replace(/[^\d]/g, "");

  if (!addCountryCode || !phone?.length) {
    return phone;
  }

  const code = "+" + (phone.startsWith("1") ? "" : "1");
  return code + phone;
};

export function prettyPrintPhoneNumber(phone: string) {
  return (
    "(" +
    phone?.substring(2, 5) +
    ") " +
    phone?.substring(5, 8) +
    "-" +
    phone?.substring(8, 12)
  );
}

export const prettyMonthDayYear = (
  date: string | undefined | null | Date,
  short?: boolean
) => {
  if (!date) {
    return "";
  }
  return moment(date).format(short ? "MMM D, YYYY" : "MMMM D, YYYY");
};

export const getPrettyTime = (
  date: Date | string | null | undefined,
  short?: boolean,
  displayTime = false
): string => {
  if (!date) {
    return "";
  }

  return moment(date).format(
    short
      ? `MMM D, YYYY ${displayTime ? "h:mm A" : ""}`
      : `MMMM D, YYYY ${displayTime ? "h:mm A" : ""}`
  );
};

export const getPrettyTimeWithoutDate = (
  date: Date | string | null | undefined
): string => {
  if (!date) {
    return "";
  }
  return moment(date).format("h:mm A");
};

export const centsToDollars = (cents: number) => {
  const dollars = Math.round((cents / 100) * 1e2) / 1e2;
  // this is to make sure we always have 2 decimal places and the type is a string
  return dollars.toFixed(2);
};

export const useDebounce = (value: string, wait = 200) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, wait);

    return () => {
      clearTimeout(handler);
    };
  }, [value, wait]);

  return debouncedValue.trim();
};

export const numberWithCommas = (x: number | undefined) => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
};

export const convertToCSV = (json: Parameters<typeof unparse>[0]) => {
  return unparse(json, {
    quotes: false,
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ",",
    header: true,
    newline: "\r\n",
    skipEmptyLines: false,
  });
};

export const downloadCSV = (csv: string, filename: string) => {
  const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(csvBlob);
  link.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getLatLng = (latLng: string | null) => {
  if (!latLng) return;
  const match = latLng.match(/([-\d.]+),([-\d.]+)/);
  if (match) {
    const [_, lng, lat] = match;
    return { lat, lng };
  }
  return null;
};

export const getLatLngAsNumber = (latLng: string) => {
  const stringLatLng = getLatLng(latLng);
  return {
    lat: Number(stringLatLng?.lat),
    lng: Number(stringLatLng?.lng),
  };
};

export const sanitizeNumeric = (numeric: string) => {
  return numeric.replace(/([^0-9]|\+|\.)/g, "");
};

function readBuffer(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

function checkFileHeader(headers: Uint8Array) {
  let header = "";
  for (const headerByte of headers) {
    header += headerByte.toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return false;
  }
}
async function detectImageType(image: File) {
  const buffers = await readBuffer(image);
  if (typeof buffers === "string" || buffers === null) return true;
  const uint8Array = new Uint8Array(buffers).subarray(0, 4);
  return checkFileHeader(uint8Array);
}

export async function convertHeicToPng(imageToConvert: File) {
  if ((await detectImageType(imageToConvert)) !== false) {
    return imageToConvert;
  }
  // This module will only be loaded on the client side
  const heic2any = (await import("heic2any")).default;

  const convertedBlob = await heic2any({
    blob: imageToConvert,
    toType: "image/png",
  });

  if (Array.isArray(convertedBlob)) {
    return { error: "Error converting image" };
  }

  const fileNameNoExt = imageToConvert.name.slice(
    0,
    imageToConvert.name.lastIndexOf(".")
  );

  return new File([convertedBlob], `${fileNameNoExt}.png`, {
    type: "image/png",
  });
}

export function getSelectedImage(file: File) {
  const fileUrl = URL.createObjectURL(file);
  // Create a Blob object from the selected file
  const blob = new Blob([file], {
    type: file.type,
  });

  return {
    uri: fileUrl,
    blob,
    name: file.name,
  };
}

export function getEstimateTotal(request: RequestDataType) {
  let proposedTotal = 0;
  if (request) {
    const { total } = getRequestTotalValues({
      services: transformServiceApproval(request.services),
      request: {
        id: request?.id,
        fleetId: request?.fleetId ?? null,
        taxRate: request?.taxRate,
        taxRateLaborCollisionRepair: request?.taxRateLaborCollisionRepair,
        taxRatePaintSupplyMaterial: request?.taxRatePaintSupplyMaterial,
      },
      requestCreatedDate: request.createdAt,
      discountInfo: request.promoCode,
    });
    proposedTotal = total ? total / 100 : 0;
  }
  return proposedTotal;
}

export function getFileSize(sizeInBytes: number) {
  const size = filesize(sizeInBytes);

  if (typeof size !== "string") {
    return null;
  }

  return size;
}
export const camelToTitle = (camelCaseString: string): string => {
  return camelCaseString
    .replace(/(?!^)(?=[A-Z])/g, " ")
    .replace(/^./, (str) => str.toUpperCase());
};
